<template>
  <section>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div class="text-left">
        <div class="form-item">
          <div class="flex-col text-left mg-b-25">
            <span class="fs-16">Please prepare the following documents for Application</span>
            <span class="fs-16">填寫表格前，請先拿出以下投保所需文件</span>
          </div>
          <div class="center-box">
            <ul>
              <li v-if="formData.client_type==1">HKID 香港身份證</li>
              <li v-if="formData.client_type==2">CI 公司註冊證書</li>
              <li>Driving Licence 司機駕駛執照</li>
              <li>Vehicle Registration Document 車輛登記文件 - 牌簿</li>
              <li>NCB proof (If applicable) 無索償折扣證明（如適用）</li>
            </ul>
          </div>
        </div>
        <div class="form-item form-half">
          <div class="flex-col text-left">
            <span>Client Type</span>
            <span >客戶類型</span>
          </div>
          <el-form-item
            ref="client_type"
            :rules="rules.select"
            prop="client_type"
          >
            <v-radio-group
              v-model="formData.client_type"
              border
              flex
              :list="personTypeist"></v-radio-group>
          </el-form-item>
        </div>
        <template v-if="formData.client_type==1">
          <div class="form-item form-half">
            <div class="flex-col text-left">
              <span>Are you the vehicle owner?</span>
              <span >閣下是否車主本人？</span>
            </div>
            <el-form-item
              ref="is_policyholder"
              :rules="rules.select"
              prop="is_policyholder"
            >
              <v-radio-group
                v-model="formData.is_policyholder"
                border
                flex
                :list="yesOrNoOption"></v-radio-group>
            </el-form-item>
          </div>
          <template v-if="formData.is_policyholder==2">
            <div class="form-item form-half">
              <div class="flex-col text-left">
                <span>Authorised Person Name</span>
                <span >代辦人名稱</span>
              </div>
              <el-form-item
                ref="agent_name"
                :rules="rules.input"
                prop="agent_name"
              >
                <v-input
                  v-model="formData.agent_name"
                ></v-input>
              </el-form-item>

            </div>
            <div class="form-item form-half">
              <div class="flex-col text-left">
                <span>Authorised Person Mobile Number</span>
                <span >代辦人手機號碼</span>
              </div>
              <el-form-item
                ref="agent_mobile_no"
                :rules="rules.agent_mobile_no"
                prop="agent_mobile_no"
              >
                <v-input
                  v-model="formData.agent_mobile_no"
                  preappend="+852"
                  :maxlength="8"
                  key-number
                  type="tel"
                ></v-input>
              </el-form-item>
            </div>
          </template>
        </template>
        <div class="form-item form-half">
          <div class="flex-col text-left">
            <span>Policy Type</span>
            <span>保單類型</span>
          </div>
          <el-form-item
            ref="policy_type"
            :rules="rules.select"
            :show-message="showRuleMessage"
            prop="policy_type">
            <v-radio-group
              v-model="formData.policy_type"
              :label-key="'name1'"
              isSplit
              border
              block
              :list="globalOptions.client_policy_type"></v-radio-group>
          </el-form-item>
        </div>
        <div class="form-item form-half">
          <div class="flex-col text-left">
            <span>Policy Effective Date</span>
            <span>保單生效日期</span>
          </div>
          <el-form-item
            :rules="rules.select"
            :show-message="showRuleMessage"
            prop="effective_date">
            <v-date-picker
              v-model="formData.effective_date"
              :picker-options="dayPickerOptions"
            ></v-date-picker>
          </el-form-item>
        </div>

        <!--        <div-->
        <!--          v-for="(item,index) in driverFormList1"-->
        <!--          :key="index"-->
        <!--          class="form-item form-half"-->
        <!--        >-->
        <!--          <template  v-if="!item.noshow">-->
        <!--            <div class="flex-col text-left">-->
        <!--              <span :content="item.en_content">{{item.en}}</span>-->
        <!--              <span :content="item.zh_content">{{item.zh}}</span>-->
        <!--            </div>-->
        <!--            <div class="flex-col text-left color-gray">-->
        <!--              <span class="fs-12 lh-150">{{item.en_tip}}</span>-->
        <!--              <span class="fs-12 lh-150">{{item.zh_tip}}</span>-->
        <!--            </div>-->
        <!--            <el-form-item-->
        <!--              :ref="item.key=='policy_holder_is_driver'?item.key:`driver.0.${item.key}`"-->
        <!--              :show-message="showRuleMessage"-->
        <!--              :prop="item.key=='policy_holder_is_driver'?item.key:`driver.0.${item.key}`"-->
        <!--              :rules="item.key=='id_no'?rules.policy_holder_id_no:ruleType(item.type)"-->
        <!--            >-->
        <!--              <template v-if="item.type==1">-->
        <!--                <v-input-->
        <!--                  v-model="formData.driver[0][item.key]"-->
        <!--                  :disabled="item.disabled"-->
        <!--                  :suffixText="item.append">-->
        <!--                </v-input>-->
        <!--              </template>-->
        <!--              <template v-if="item.type==2">-->
        <!--                <v-select-->
        <!--                  v-model="formData.driver[0][item.key]"-->
        <!--                  :disabled="item.disabled"-->
        <!--                  :label-key="'name1'"-->
        <!--                  :list="item.list"></v-select>-->
        <!--              </template>-->
        <!--              <template v-if="item.type==3">-->
        <!--                <v-radio-group-->
        <!--                  v-if="item.key=='policy_holder_is_driver'"-->
        <!--                  v-model="formData.policy_holder_is_driver"-->
        <!--                  block-->
        <!--                  border-->
        <!--                  :list="item.list"-->
        <!--                  @change="handleSameDriver"></v-radio-group>-->
        <!--                <v-radio-group-->
        <!--                  v-else-->
        <!--                  v-model="formData.driver[0][item.key]"-->
        <!--                  :disabled="item.disabled"-->
        <!--                  block-->
        <!--                  border-->
        <!--                  :list="item.list"></v-radio-group>-->
        <!--              </template>-->
        <!--              <template v-if="item.type==4">-->
        <!--                &lt;!&ndash;                <v-date-picker&ndash;&gt;-->
        <!--                &lt;!&ndash;                  v-model="formData.driver[0][item.key]"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  :disabled="item.disabled"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  has-deault&ndash;&gt;-->
        <!--                &lt;!&ndash;                ></v-date-picker>&ndash;&gt;-->
        <!--                <v-birth-day-->
        <!--                  v-model="formData.driver[0][item.key]"-->
        <!--                  :disabled="item.disabled"-->
        <!--                ></v-birth-day>-->
        <!--              </template>-->
        <!--            </el-form-item>-->
        <!--          </template>-->
        <!--        </div>-->

        <!--        <div class="form-item form-half">-->
        <!--          <div class="flex-col text-left">-->
        <!--            <span>Type of Motorcycle Driving licence</span>-->
        <!--            <span>電單車駕駛執照類型</span>-->
        <!--          </div>-->
        <!--          <el-form-item-->
        <!--            ref="vehicle_detail.ncd"-->
        <!--            :show-message="showRuleMessage"-->
        <!--            :rules="rules.select"-->
        <!--            prop="vehicle_detail.ncd">-->
        <!--            <v-select-->
        <!--              v-model="formData.driver[0].driving_licence_id"-->
        <!--              :list="globalOptions.driving_licence"-->
        <!--            ></v-select>-->
        <!--          </el-form-item>-->
        <!--        </div>-->
        <!--        <div class="form-item form-half">-->
        <!--          <div class="flex-col text-left">-->
        <!--            <span>Are all the driver(s) has not been deducted driving offence points in the past 3 years?</span>-->
        <!--            <span>是否所有記名司機均沒有於過往三年內被扣分？</span>-->
        <!--          </div>-->
        <!--          <el-form-item-->
        <!--            ref="driver.is_deducted_point"-->
        <!--            :rules="rules.select"-->
        <!--            prop="driver.is_deducted_point">-->
        <!--            <v-select-->
        <!--              v-model="formData.driver.is_deducted_point"-->
        <!--              :list="deductedOption"-->
        <!--            ></v-select>-->
        <!--          </el-form-item>-->
        <!--        </div>-->
        <!--        <div-->
        <!--          v-if="driver.is_deducted_point==1"-->
        <!--          class="form-item form-half">-->
        <!--          <div class="flex-col text-left">-->
        <!--            <span>Number of driving offence point in last 3 years</span>-->
        <!--            <span>過往3年內被扣分數</span>-->
        <!--          </div>-->
        <!--          <el-form-item-->
        <!--            ref="driver.deducted_points"-->
        <!--            :rules="rules.select"-->
        <!--            prop="driver.deducted_points">-->
        <!--            <v-select-->
        <!--              v-model="formData.driver.deducted_points"-->
        <!--              :list="globalOptions.deducted_points"-->
        <!--            ></v-select>-->
        <!--          </el-form-item>-->
        <!--        </div>-->
        <div class="form-item form-half">
          <div class="flex-col text-left">
            <span>NCD</span>
            <span>無索償折扣</span>
          </div>
          <el-form-item
            ref="vehicle_detail.ncd"
            :show-message="showRuleMessage"
            :rules="rules.select"
            prop="vehicle_detail.ncd">
            <v-select
              v-model="formData.vehicle_detail.ncd"
              :list="ncdOption(1003)"
            ></v-select>
          </el-form-item>
        </div>
        <template  v-if="ncd_data!=0">
          <!--          <div-->
          <!--            class="form-item form-half">-->
          <!--            <div class="flex-col text-left">-->
          <!--              <span>Previous Insurer</span>-->
          <!--              <span>前保險公司</span>-->
          <!--            </div>-->
          <!--            <el-form-item-->
          <!--              ref="vehicle_detail.previous_insurance_company_id"-->
          <!--              :show-message="showRuleMessage"-->
          <!--              :rules="rules.select"-->
          <!--              prop="vehicle_detail.previous_insurance_company_id">-->
          <!--              <v-select-->
          <!--                v-model="formData.vehicle_detail.previous_insurance_company_id"-->
          <!--                :list="globalOptions.previous_insurance_company"-->
          <!--              ></v-select>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <div
            class="form-item form-half">
            <div class="flex-col text-left">
              <span>Is the subject Insured vehicle be the same as the one under the previous policy?</span>
              <span>現投保車輛是否與此保單受保車輛為同一車輛？</span>
            </div>
            <el-form-item
              ref="vehicle_detail.is_same_previous_car"
              :rules="rules.select"
              prop="vehicle_detail.is_same_previous_car">
              <v-radio-group
                v-model="formData.vehicle_detail.is_same_previous_car"
                border
                block
                :list="yesOrNoOption"
              ></v-radio-group>
            </el-form-item>
          </div>
          <div
            class="form-item form-half">
            <div class="flex-col text-left">
              <span>Previous Policy Number</span>
              <span>前保單號碼</span>
            </div>
            <el-form-item
              ref="vehicle_detail.policy_number"
              :rules="rules.input"
              prop="vehicle_detail.policy_number">
              <v-input
                v-model="formData.vehicle_detail.policy_number"
              ></v-input>
            </el-form-item>
          </div>
          <div
            class="form-item form-half">
            <div class="flex-col text-left">
              <span>Previous Policy Registration Mark</span>
              <span>前車輛登記號碼</span>
            </div>
            <el-form-item
              ref="vehicle_detail.registration_number"
              :rules="rules.input"
              prop="vehicle_detail.registration_number">
              <v-input
                v-model="formData.vehicle_detail.registration_number"
              ></v-input>
            </el-form-item>
          </div>
        </template>
        <!--        <div class="form-item form-half">-->
        <!--          <div class="flex-col text-left">-->
        <!--            <span>Coverage</span>-->
        <!--            <span>保障類型</span>-->
        <!--          </div>-->
        <!--          <el-form-item-->
        <!--            :show-message="showRuleMessage"-->
        <!--            prop="cover_type">-->
        <!--            <v-select-->
        <!--              v-model="formData.coverage.cover_type"-->
        <!--              disabled-->
        <!--              :label-key="'name1'"-->
        <!--              :list="globalOptions.coverage_type"></v-select>-->
        <!--          </el-form-item>-->
        <!--        </div>-->
        <v-button @click="handleNext">下一步 Next</v-button>
      </div>
    </el-form>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MixinsPolicyForm from '@/mixins/policyForm'
import MixinsOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'

export default {
  name: 'policyDetailsForm',
  mixins:[MixinsOptions,MixinRules,MixinsPolicyForm],
  data() {
    return {
      nextPath:'/quoting/insure/policyholder',
      formData:{
        client_type:'',
        policy_type:'',
        effective_date:'',
        previous_insurance_company_id:'',
        agent_mobile_no:'',
        is_policyholder:'',
        agent_name:'',
        vehicle_detail:{
          ncd:0,
          previous_insurance_company_id:'',
          is_same_previous_car:'',
          policy_number:'',
          registration_number:'',
        },
        // driver:[
        //   {
        //     first_name:'',
        //     last_name:'',
        //     title:'',
        //     occ_id:'',
        //     birth:'',
        //     id_no:'',
        //     id_type:'',
        //     relationship_id:'',
        //     driving_licence_id:'',
        //     has_accident:1,
        //     accidents:'',
        //     is_deducted_point:'',
        //     deducted_points:'',
        //   }
        // ]
      }
    }
  },
  computed: {
    ncd_data(){
      let {vehicle_detail={}} = this.formData||{}
      let {ncd=0} = vehicle_detail||{}
      return ncd||0
    },
    dayPickerOptions(){
      return{
        disabledDate: (time) => {
          let now = Date.now()
          let selectTime = time.getTime()
          let diff = (selectTime - now) / (3600 * 1000 * 24)
          return diff < -1
        },
      }
    },

  },
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){
        Object.keys(this.formData).some(key=>{
          if(val&&val[key]){
            this.formData[key] = val[key]
          }
        })
      }
    }
  },
  mounted () {
  },
  methods: {
    onConfirm(){

    },
    network(){
      return {
        saveQuotation: async (params) => {

        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mg-b-25{
  margin-bottom: 25px;
}
  .center-box{
    background: #D0DAFF;
    border-radius: 10px;
    padding: 15px;
    ul{
      padding-left: 20px;
      li{
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.02em;
        list-style: disc;
      }
    }
  }
</style>
